<template>
  <b-card no-body title="Organizations">
    <div>
      <b-button-toolbar justify class="toolbar-top-margin">
        <b-form-group label-align-sm="left" label-size="md" label-for="filterInput" class="mb-0 search-bar-margin">
          <b-input-group size="sm">
            <b-form-input id="filterInput" v-model="filterBy" type="search" placeholder="Type to Search" debounce="500" />
          </b-input-group>
        </b-form-group>
        <b-button-group class="mr-2 mb-1">
          <span class="d-inline-block" tabindex="0" v-b-tooltip.left.v-warning title="Please contact dev support to add organizations">
            <b-button
              v-b-tooltip.hover.left
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-warning"
              class="btn-icon"
              title="Please contact dev support to add organizations"
              @click="addNewOrganizationItem"
              disabled
            >
              <feather-icon icon="FilePlusIcon" />
            </b-button>
          </span>
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" class="btn-icon" @click="loadAllOrgs">
            <b-spinner v-if="getLoading" small style="margin-bottom: 3px" />
            <feather-icon icon="RefreshCwIcon" v-if="!getLoading" />
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </div>
    <b-overlay :show="getLoading" rounded="sm" opacity="0.8">
      <b-table responsive :fields="fields" :items="getOrgList" :current-page="currentPage">
        <template #cell(name)="data">
          <b-link v-model="data.detailsShowing" @click="data.toggleDetails">
            {{ data.value }}
          </b-link>
        </template>
        <template #cell(created)="data">
          {{ showDateInFormat(data.value) }}
        </template>
        <template #cell(billing)="data">
          {{ getAddressString(data.value) }}
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
          <template v-if="editOrganizationItem === row.item.OrganizationId">
            <b-card title="Organization" style="max-width: 80%" class="mb-2" bg-variant="light-secondary" border-variant="secondary">
              <b-card-text>
                <b-form>
                  <b-form-group id="input-group-1" label-for="input-imageurl" label-cols="2" label-align="right">
                    <template #label>
                      <b>Name:</b>
                    </template>
                    <b-form-input id="input-imageurl" maxlength="500" v-model="form.name" type="url" placeholder="Enter organization's name" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-2" label-cols="2" label-align="right">
                    <template #label>
                      <b>Email:</b>
                    </template>
                    <b-form-input id="input-articleurl" maxlength="500" v-model="form.email" type="url" placeholder="Enter email address" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-3" label-cols="2" label-align="right">
                    <template #label>
                      <b>Phone:</b>
                    </template>
                    <b-form-input id="input-headline" maxlength="100" v-model="form.phone" placeholder="Enter phone number" required></b-form-input>
                  </b-form-group>

                  <b-form-group id="input-group-5" label-cols="2" label-align="right">
                    <template #label>
                      <b>Homepage URL:</b>
                    </template>
                    <b-form-input id="input-summary" maxlength="100" rows="5" v-model="form.homepage" placeholder="Enter homepage URL" required></b-form-input>
                  </b-form-group>

                  <b-tabs content-class="mt-3" align="center">
                    <b-tab title="Billing" active>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #1:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.billingAddress.addressLine1" placeholder="Enter Address #1" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #2:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.billingAddress.addressLine2" placeholder="Enter Address #2" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #3:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.billingAddress.addressLine3" placeholder="Enter Address #3" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>City:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.billingAddress.city" placeholder="Enter city" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>State:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.billingAddress.state" placeholder="Enter state" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Zip:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.billingAddress.zip" placeholder="Enter zip" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>County:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.billingAddress.county" placeholder="Enter county" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Country:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.billingAddress.country" placeholder="Enter country" required></b-form-input>
                      </b-form-group>
                    </b-tab>
                    <b-tab title="Mailing">
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #1:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.mailingAddress.addressLine1" placeholder="Enter Address #1" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #2:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.mailingAddress.addressLine2" placeholder="Enter Address #2" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Address #3:</b>
                        </template>
                        <b-form-textarea id="input-summary" maxlength="500" v-model="form.mailingAddress.addressLine3" placeholder="Enter Address #3" required></b-form-textarea>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>City:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.mailingAddress.city" placeholder="Enter city" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>State:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.mailingAddress.state" placeholder="Enter state" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Zip:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="100" v-model="form.mailingAddress.zip" placeholder="Enter zip" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>County:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="1000" v-model="form.mailingAddress.county" placeholder="Enter county" required></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-5" label-cols="2" label-align="right">
                        <template #label>
                          <b>Country:</b>
                        </template>
                        <b-form-input id="input-summary" maxlength="1000" v-model="form.mailingAddress.country" placeholder="Enter country" required></b-form-input>
                      </b-form-group>
                    </b-tab>
                  </b-tabs>

                  <br />
                  <b-form-group id="input-toggles" label=" " label-cols="2" label-align="right">
                    <b-form-checkbox v-model="form.equityLicense">Equity License?</b-form-checkbox>
                    <br />
                    <b-form-checkbox v-model="form.premium">Premium?</b-form-checkbox>
                    <br />
                    <b-form-checkbox v-model="form.compassion">Compassion?</b-form-checkbox>
                    <br />
                    <b-form-checkbox v-model="form.strictMode">Strict Mode?</b-form-checkbox>
                  </b-form-group>
                  <br />
                  <b-button @click="saveEdited" variant="primary">Submit</b-button>
                  <b-button @click="cancelSave(row.item)" variant="danger">Cancel</b-button>
                </b-form>
              </b-card-text>
            </b-card>
          </template>
          <template v-else>
            <b-button
              id="edit_organization_button"
              class="btn-icon success"
              v-b-tooltip.hover
              variant="gradient-primary"
              title="Edit Organization Data"
              @click="editItem(row.item)"
            >
              Edit
            </b-button>
            <br />
            <br />
            <b-card>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1"> <strong> Details: </strong></b-col>
              </b-row>
              Phone: {{ row.item.phone }}<br />
              Email: {{ row.item.email }}<br />
              Homepage: {{ row.item.homepage }} <br />
              <template v-if="row.item.mailing">
                Mailing addr: <br />
                getAddressString(row.item.mailing)
              </template>
              <br />
              Id: {{ row.item.OrganizationId }} <br />
              <br />
            </b-card>
          </template>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <div class="col-1">
          <b-form-select id="perPageSelect" :value="getPerPage" size="sm" inline :options="pageOptions" @change="perPageChanged" />
          <p class="text-nowrap mb-md-0 mr-1"><b>Total rows:</b> {{ getTotalRows }}</p>
        </div>

        <!-- pagination -->
        <div>
          <b-pagination
            :value="currentPage"
            :total-rows="getTotalRows"
            :per-page="getPerPage"
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            limit="1"
            hide-ellipsis
            hide-goto-end-buttons
            @change="onPageChanged"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  getOrgList,
  filterBy,
  setPerPage,
  getLoading,
  onLoadAllOrgs,
  saveEditOrganizationItem,
  cancelNewItem,
  addNewItem,
  removeNotAddedItems,
  getTotalRows,
  getPerPage,
  validateIfDetailsOpen
} from "@/views/organization/orgRequests";
import { DEFAULT_PAGE_OPTIONS } from "@/listo/defaults";
import { onMounted, ref, watch } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  name: "AllOrganizations",
  components: {
    Ripple
  },
  directives: {
    Ripple
  },
  setup(_, { root }) {
    const currentPage = ref(1);
    const pageOptions = DEFAULT_PAGE_OPTIONS;
    const fields = [
      // 'mark_resolved',
      {
        key: "name",
        label: "Name"
      },
      "equity",
      "premium",
      "compassion",
      "billing",
      "created"
    ];
    const DEFAULT_FORM = {
      name: "",
      phone: "",
      email: "",
      homepage: "",
      billingAddress: {},
      mailingAddress: {},
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      country: "",
      equityLicense: false,
      premium: false,
      compassion: false,
      OrganizationId: "",
      strictMode: true
    };
    const DEFAULT_ADDRESS = {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      state: "",
      zip: "",
      county: "",
      country: ""
    };
    const editOrganizationItem = ref(-1);
    const form = DEFAULT_FORM;
    form.billingAddress = { ...DEFAULT_ADDRESS };
    form.mailingAddress = { ...DEFAULT_ADDRESS };
    let nextId = 99999;

    const editItem = (item) => {
      editOrganizationItem.value = item.OrganizationId;
      form.name = item.name;
      form.phone = item.phone;
      form.email = item.email;
      form.homepage = item.homepage;
      form.billingAddress = item.billingAddress ? { ...item.billingAddress } : { ...DEFAULT_ADDRESS };
      form.mailingAddress = item.mailingAddress || { ...DEFAULT_ADDRESS };
      form.equityLicense = item.equityLicense;
      form.premium = item.premium;
      form.compassion = item.compassion;
      form.strictMode = item.strictMode;
      form.OrganizationId = item.OrganizationId;
    };

    const addNewOrganizationItem = () => {
      if (editOrganizationItem.value !== -1 || validateIfDetailsOpen()) {
        return;
      }
      let newData = { ...DEFAULT_FORM };
      newData.name = "New Organization";
      newData.billingAddress = { ...DEFAULT_ADDRESS };
      newData.mailingAddress = { ...DEFAULT_ADDRESS };
      newData._showDetails = true;
      newData.isNew = true;
      newData.OrganizationId = nextId;
      editOrganizationItem.value = nextId;
      nextId++;
      addNewItem(newData);
    };

    const saveEdited = async () => {
      let pd = {};
      pd.name = form.name;
      pd.phone = form.phone;
      pd.email = form.email;
      pd.homepage = form.homepage;
      pd.billingAddress = form.billingAddress;
      pd.mailingAddress = form.mailingAddress;
      pd.equityLicense = form.equityLicense;
      pd.premium = form.premium;
      pd.compassion = form.compassion;
      pd.strictMode = form.strictMode;
      pd.OrganizationId = form.OrganizationId;
      try {
        await saveEditOrganizationItem(pd);
      } finally {
        editOrganizationItem.value = -1;
        form.name = "";
        form.phone = "";
        form.email = "";
        form.homepage = "";
        form.billingAddress = { ...DEFAULT_ADDRESS };
        form.mailingAddress = { ...DEFAULT_ADDRESS };
        form.equityLicense = false;
        form.premium = false;
        form.compassion = false;
        form.strictMode = true;
        form.OrganizationId = "";
      }
    };

    const cancelSave = (data) => {
      if (data.isNew) {
        cancelNewItem(data.OrganizationId);
      }
      editOrganizationItem.value = -1;
    };

    watch(filterBy, (newValue, oldValue) => {
      initalLoadOrgs();
    });

    const showDateInFormat = (date) => moment(date).format("YYYY-MM-DD hh:mm:ss a");

    const initalLoadOrgs = async () => loadAllOrgs({ prevPage: false, nextPage: false, reset: true });

    const perPageChanged = async (val) => {
      setPerPage(val);
      await initalLoadOrgs();
    };

    const loadAllOrgs = async () => {
      editOrganizationItem.value = -1;
      await onLoadAllOrgs({ prevPage: false, nextPage: false, reset: true });
    };

    const onPageChanged = async (val) => {
      if (val == 1) {
        currentPage.value = val;
        await loadAllOrgs({ prevPage: false, nextPage: false, reset: true });
        return;
      }
      if (currentPage.value > val) {
        await loadAllOrgs({ prevPage: true, nextPage: false });
        currentPage.value = val;
        return;
      }
      await loadAllOrgs({ prevPage: false, nextPage: true });
      currentPage.value = val;
    };

    const getAddressString = (addr) => {
      let ret = "";
      if (addr.addressLine1) {
        ret += addr.addressLine1 + "<br/>";
      }
      if (addr.addressLine2) {
        ret += addr.addressLine2 + "<br/>";
      }
      if (addr.addressLine3) {
        ret += addr.addressLine3 + "<br/>";
      }
      let needbr = false;
      if (addr.city) {
        ret += addr.city;
        needbr = true;
      }
      if (addr.state) {
        ret += addr.state;
        needbr = true;
      }
      if (addr.zip) {
        ret += addr.zip;
        needbr = true;
      }
      if (needbr) {
        ret += "<br/>";
      }
      if (addr.county) {
        ret += addr.county + "<br/>";
      }
      if (addr.country) {
        ret += addr.country + "<br/>";
      }
      return ret;
    };

    onMounted(() => {
      removeNotAddedItems();
      filterBy.value = "";
    });

    return {
      getOrgList,
      filterBy,
      setPerPage,
      getLoading,
      onLoadAllOrgs,
      getTotalRows,
      getPerPage,
      onPageChanged,
      perPageChanged,
      showDateInFormat,
      currentPage,
      pageOptions,
      fields,
      getAddressString,
      editOrganizationItem,
      editItem,
      saveEdited,
      cancelSave,
      addNewOrganizationItem,
      form,
      loadAllOrgs
    };
  }
};
</script>

<style scoped>
.toolbar-top-margin {
  margin-top: 10px;
}
.search-bar-margin {
  margin-left: 15px;
}
.new-only-switch {
  margin-left: 20px;
}
</style>
